export default {
  methods: {
    calculateBalance(amountPaid, creditApplied, bill) {
      const amount = parseFloat(amountPaid) || 0
      const credit = parseFloat(creditApplied) || 0
      const balance = parseFloat(bill) - (amount + credit)

      if (balance < 0) {
        return 0
      }

      return balance
    },
  },
}
